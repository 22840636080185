import React from "react";
// //Import Icons
import creativity from "../img/launch.svg";
import patience from "../img/ying-yang.svg";
import dedication from "../img/time-management.svg";
import enthusiasm from "../img/lightbulb.svg";
import code from "../img/code.png";
//Styles
import {About, Description, Image} from "../styles";
import styled from "styled-components";
import {scrollReveal} from "../animation";
import {useScroll} from "./useScroll";

const ServicesSection = () => {
    const [element, controls] = useScroll();
    return (
        <Services
            variants={scrollReveal}
            animate={controls}
            initial="hidden"
            ref={element}>
            <Description>
                <h2>
                    My Main <span>Strengths</span>
                </h2>
                <Cards>
                    <Card>
                        <div className="icon">
                            <img alt="icon" src={enthusiasm}/>
                            <h3>Enthusiasm</h3>
                        </div>
                        <p/>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img alt="icon" src={patience}/>
                            <h3>Patience</h3>
                        </div>
                        <p/>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img alt="icon" src={creativity}/>
                            <h3>Creativity</h3>
                        </div>
                        <p/>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img alt="icon" src={dedication}/>
                            <h3>Dedication</h3>
                        </div>
                        <p/>
                    </Card>
                </Cards>
            </Description>
            <Image>
                <img alt="code" src={code} className="code"/>
            </Image>
        </Services>
    );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }

  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .code {
      object-fit: unset;
      height: 40vh;
    }
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1270px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  flex-basis: 20rem;

  .icon {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 1rem;
      background: white;
      color: black;
      padding: 1rem;
    }
  }
`;

export default ServicesSection;
