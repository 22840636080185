import React from "react";
//Page Components
import AboutSection from "../components/AboutSection";
import ServicesSection from "../components/ServicesSection";
import FaqSection from "../components/FaqSection";
//Animations
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {CvSection} from "../styles";
import cv from "../img/cv.png";

const AboutUs = () => {
    return (
        <motion.div
            exit="exit"
            variants={pageAnimation}
            initial="hidden"
            animate="show"
        >
            <AboutSection/>
            <ServicesSection/>
            <FaqSection/>
            <CvSection>
                <h2>
                    My <span>CV</span>
                </h2>
                <img alt="code" src={cv} style={{width: '100%'}}/>
            </CvSection>
            <ScrollTop/>
        </motion.div>
    );
};

export default AboutUs;
