import React from "react";
import home1 from "../img/home1.png";
import {About, Description, Image, Hide} from "../styles";
import {motion} from "framer-motion";
import {titleAnim, fade, photoAnim} from "../animation";
import Wave from "./Wave";
import {Link} from "react-router-dom";

const AboutSection = () => {
    return (
        <About>
            <Description>
                <motion.div>
                    <Hide>
                        <motion.h2 variants={titleAnim}>Hi</motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>I'm Oren Cohen</motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>
                            <span>Full Stack Web Developer</span>
                        </motion.h2>
                    </Hide>
                </motion.div>
                <motion.p variants={fade}>
                    I love web development and always excited to develop end-to-end projects.
                    Please visit my work page to learn more about my developing journey, continuous learning, skills,
                    and creativity.
                </motion.p>
                <Link to="/contact">
                    <motion.button variants={fade}>Contact Me</motion.button>
                </Link>
            </Description>
            <Image>
                <motion.img variants={photoAnim} src={home1} alt="me"/>
            </Image>
            <Wave/>
        </About>
    );
};

export default AboutSection;
