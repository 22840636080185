import React from "react";
import {motion} from "framer-motion";
import {pageAnimation, titleAnim} from "../animation";
import styled from "styled-components";

const ContactUs = () => {
    return (
        <ContactStyle
            exit="exit"
            variants={pageAnimation}
            initial="hidden"
            animate="show"
            style={{background: "#fff"}}
        >
            <Title>
                <Hide>
                    <motion.h2 variants={titleAnim}>Get in touch.</motion.h2>
                </Hide>
            </Title>
            <div>
                <Hide>
                    <Social variants={titleAnim}>
                        <Circle/>
                        <Atag href={'mailto:orencodes@gmail.com'}>
                            <h2>Send me an email.</h2>
                        </Atag>
                    </Social>
                </Hide>
                <Hide>
                    <Social variants={titleAnim}>
                        <Circle/>
                        <Atag href={'https://www.linkedin.com/in/oren-cohen-549885108/'}>
                            <h2>Visit my Linkedin.</h2>
                        </Atag>
                    </Social>
                </Hide>
                <Hide>
                    <Social variants={titleAnim}>
                        <Circle/>
                        <Atag href={'https://github.com/OrenCODE'}>
                            <h2>Check my GitHub.</h2>
                        </Atag>
                    </Social>
                </Hide>
            </div>
        </ContactStyle>
    );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  min-height: 90vh;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
  @media (max-width: 1282px) {
  padding: 2rem 10rem;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    padding: 2rem;
  }
`;
const Title = styled.div`
  margin-bottom: 4rem;
  color: black;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
const Circle = styled.div`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: #353535;
`;

const Atag = styled.a`
  text-decoration: unset;
  color: unset;
`;

const Social = styled(motion.div)`
  display: flex;
  align-items: center;

  h2 {
    margin: 2rem;
  }
`;

export default ContactUs;
