import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {MovieState} from "../movieState";
import {motion} from "framer-motion";
import {fade, pageAnimation} from "../animation";

const MovieDetail = () => {
    const history = useHistory();
    const url = history.location.pathname;
    const [movies, setMovies] = useState(MovieState);
    const [movie, setMovie] = useState(null);

    useEffect(() => {
        const currentMovie = movies.filter((stateMovie) => stateMovie.url === url);
        setMovie(currentMovie[0]);
        console.log(setMovies);
    }, [movies, url]);

    const redirect = (outerUrl) => {
        window.open(outerUrl, "_blank");
    }

    return (
        <>
            {movie && (
                <Details
                    exit="exit"
                    variants={pageAnimation}
                    initial="hidden"
                    animate="show"
                >
                    <HeadLine onClick={() => redirect(movie.outerUrl)}>
                        <h2>{movie.title}</h2>
                        <img src={movie.mainImg} alt="movie"/>
                    </HeadLine>
                    <Awards>
                        {movie.awards.map((award) => (
                            <Award
                                title={award.title}
                                description={award.description}
                                key={award.title}
                            />
                        ))}
                    </Awards>
                    <div style={{display: 'flex', placeContent: 'center', gap: 30, paddingBottom: '6rem'}}>
                        <motion.button
                            variants={fade}
                            onClick={() => redirect(movie.outerUrl)}
                            style={{width: '200px'}}>
                            Go to Project
                        </motion.button>
                        {movie.loginDetails && (
                            <div style={{ display: 'flex', flexDirection: 'column' , gap: 8}}>
                                <div>Quick Login</div>
                                <div>{`Email: ${movie.loginDetails.email}`}</div>
                                <div>{`Password: ${movie.loginDetails.password}`}</div>
                            </div>
                        )}
                    </div>
                    <ImageDisplay>
                        <img src={movie.secondaryImg} alt="movie"/>
                    </ImageDisplay>
                </Details>
            )}
        </>
    );
};

const Details = styled(motion.div)`
  color: white;
`;
const HeadLine = styled.div`
  cursor: pointer;
  min-height: 90vh;
  padding-top: 20vh;
  position: relative;

  h2 {
    min-width: fit-content;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }

  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;
const Awards = styled.div`
  min-height: 40vh;
  display: flex;
  margin: 5rem 10rem;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1500px) {
    display: block;
    margin: 2rem 2rem;
  }
`;
const AwardStyle = styled.div`
  padding: 5rem;

  h3 {
    font-size: 2rem;
  }

  .line {
    width: 100%;
    background: #23d997;
    height: 0.5rem;
    margin: 1rem 0rem;
  }

  p {
    padding: 2rem 0rem;
  }
`;
const ImageDisplay = styled.div`
  min-height: 50vh;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

//Award Component
const Award = ({title, description}) => {
    return (
        <AwardStyle>
            <h3>{title}</h3>
            <div className="line"/>
            <p>{description}</p>
        </AwardStyle>
    );
};

export default MovieDetail;
