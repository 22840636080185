import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//Images
import sun4 from "../img/sun4.jpeg";
import vacation from "../img/vacation.jpg";
import foodjam from "../img/foodjam.png";
import weatherisco from "../img/weatherisco.png";
//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {

  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();

  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}/>
        <Frame2 variants={slider}/>
        <Frame3 variants={slider}/>
        <Frame4 variants={slider}/>
      </motion.div>
      <Movie>
        <motion.h2 variants={fade}>Chill Music Player</motion.h2>
        <motion.div variants={lineAnim} className="line"/>
        <Link to="/work/chill-player">
          <Hide>
            <motion.img variants={photoAnim} src={sun4} alt="music" />
          </Hide>
        </Link>
      </Movie>

      <Movie ref={element} variants={fade} animate={controls} initial="hidden">
        <h2>FOODJAM</h2>
        <motion.div variants={lineAnim} className="line"/>
        <Link to="/work/foodjam">
          <img src={foodjam} alt="foodjam" />
        </Link>
      </Movie>

      <Movie ref={element2} variants={fade} animate={controls2} initial="hidden">
        <h2>Vacation Master</h2>
        <motion.div variants={lineAnim} className="line"/>
        <Link to="/work/vacation-master">
          <img src={vacation} alt="vacations" />
        </Link>
      </Movie>

      <Movie ref={element3} variants={fade} animate={controls3} initial="hidden">
        <h2>Weatherisco</h2>
        <motion.div variants={lineAnim} className="line"/>
        <Link to="/work/weatherisco">
          <img src={weatherisco} alt="vacations" />
        </Link>
      </Movie>
      <ScrollTop />
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  @media (max-width: 1270px) {
    padding: 2rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background: #23d997;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #8effa0;
`;

export default OurWork;
